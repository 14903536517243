.form-group {
    padding-top: 20px;
    margin-bottom: 20px;
}

.logo-title {
    font-size: 16px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
}

.logo-design {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.logo-left {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.logo-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #F6F4EE;
    color: black;
}

.logo-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.color-picker-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color-picker-group label {
    margin-right: 10px;
    width: 130px;
    font-size: 16px;
}

.custom-color-picker {
    width: 50px;
    height: 50px;
    border: 2px solid #000;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-color-picker:hover {
    border-color: #005fcc;
    box-shadow: 0 0 5px rgba(0, 95, 204, 0.5);
}

.custom-color-picker:focus {
    border-color: #005fcc;
    box-shadow: 0 0 5px rgba(0, 95, 204, 0.8);
}