.collaboration-area {
    background-color: white;
    border-radius: 0 6px 6px 0;
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    width: 100%;
}

.files-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 2rem;
    gap: 2rem;
    scroll-behavior: smooth;
}

.data-container {
    display: flex;
    flex: 2;
    padding: 20px;
    border-left: 1px solid var(--color-kameral);
    transition: flex 0.3s ease-in-out;
    overflow: auto;
    background-color: #f9f9f9;
}

.data-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.data-row {
    display: table-row;
}

.data-cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
}

.data-header {
    display: table-cell;
    font-weight: bold;
    background-color: #e0e0e0;
    padding: 8px;
    border: 1px solid #ccc;
}

.a4-document {
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.a4-document.fit {
    height: 740px;
    width: calc(740px / 1.414);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.a4-document.actual {
    width: 210mm;
    height: 297mm;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-body {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
}

.page-body img {
    max-width: 100%;
    height: auto;
}

.zoom-controls {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}