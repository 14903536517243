.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page-content {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  max-width: 800px;
  display: flex;
  padding-top: 20px;
}

.login-button {
  width: 100%;
  max-width: 400px;
  margin-top: 40px;
}

.create-account-link {
  font-weight: 700;
  color: var(--color-link);
  text-decoration: none;
}

.create-account-link:focus,
.create-account-link:hover {
  color: var(--color-link-hover);
  text-decoration: underline;
}