.create-account-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-account-content {
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    max-width: 800px;
    display: flex;
    padding-top: 20px;
}

.create-account-button {
    width: 100%;
    max-width: 400px;
}

.terms-section {
    margin-top: 10px;
    margin-bottom: 40px;
}

.terms-button,
.login-button-link {
    color: var(--color-link);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: inherit;
}

.terms-button:hover,
.login-button-link:hover {
    color: var(--color-link-hover);
    text-decoration: underline;
}

.terms-button:focus {
    outline: 2px solid #005fcc;
    outline-offset: 2px;
}

.user-exists-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.login-button-link {
    font-size: 12px;
}