.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-kameral);
    height: 120px;
}

.left-col {
    flex: 1;
}

.center-col {
    flex: 1;
    text-align: center;
}

.right-col {
    flex: 1;
    text-align: right;
}

.right-col div {
    display: inline-block;
    text-align: right;
    font-size: 12px;
}

.right-col span {
    display: block;
}