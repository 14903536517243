.top-bar {
    background-color: var(--color-kameral);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 36px;
    padding-top: 2px;
    position: relative;
}

.search-bar {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid var(--color-neutral-200);
    padding: 5px;
    border-radius: 4px;
    width: 40%;
    position: relative;
}

.search-bar.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.search-icon {
    margin-right: 8px;
    color: #888;
}

.search-bar input {
    border: none;
    outline: none;
    width: 100%;
}

.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid var(--color-neutral-200);
    border-radius: 0 0 4px 4px;
    width: 100%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.search-results {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.search-result-item {
    font-size: 12px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.search-result-item:hover {
    background-color: var(--color-neutral-100);
}

.empty-search-result {
    font-size: 12px;
    padding: 0 10px;
    color: #888;
    font-style: italic;
}