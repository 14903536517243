.checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.checkbox-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.checkbox-label {
    font-size: 14px;
}

.error-checkbox input[type="checkbox"] {
    border: 2px solid var(--color-error);
    outline: 2px solid var(--color-error);
}

.error-text {
    color: var(--color-error);
}

.error-message {
    display: block;
    color: var(--color-error);
    font-size: 12px;
}