.team-title {
    border-bottom: 1px solid var(--color-kameral);
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.team-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: 10px;
}

.members-button {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid var(--color-neutral-300);
    border-radius: 6px;
    padding: 4px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.members-button:hover {
    background-color: var(--color-neutral-300);
}

.member-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.more-members {
    font-size: 16px;
    margin-left: 4px;
}

.member-count {
    font-size: 16px;
    margin-left: 8px;
}

.member-icon .faUser {
    color: var(--color-dark);
}

.members-navigation-area {
    border-right: 1px solid var(--color-kameral);
    background-color: white;
    border-radius: 6px 0 0 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
}

.members-navigation-area.normal-width {
    width: 25rem;
}

.members-navigation-area.small-width {
    width: 12.5rem;
}

.files-list {
    display: block;
    flex-grow: 1;
    padding: 5px 0;
    margin: 0;
    list-style-position: inside;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.empty-list {
    display: block;
    padding: 0 10px;
    flex-grow: 1;
}

.files-list li {
    font-size: 12px;
    display: list-item;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 10px;
    cursor: pointer;
    color: #333;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
}

.files-list li.selected,
.files-list li:hover {
    background-color: var(--color-neutral-200);
    border-radius: 5px;
}

.file-drop-area {
    border: 2px dashed var(--color-kameral);
    margin: 0 20px;
    padding: 20px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 6px;
    cursor: pointer;
    position: sticky;
    bottom: 0;
    background-clip: padding-box;
}

.file-drop-area:hover {
    background-color: #f0f0f0;
}

.file-input-label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload-progress {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
}

.upload-progress-bar {
    height: 6px;
    background-color: var(--color-success);
    transition: width 0.2s ease;
}

.helper-text {
    padding: 10px 20px;
}

.error-message {
    color: var(--color-error);
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 10px 20px;
}

.file-group-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
    cursor: pointer;
    font-size: 16px;

}