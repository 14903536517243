.side-menu {
    width: 4rem;
    background-color: var(--color-kameral);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-left: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
}

.icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.bottom-icon {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.team-logo-button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 6px;
    background-color: black;
    color: white;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.team-logo-button::after {
    content: '';
    position: absolute;
    top: 14px;
    width: 32px;
    height: 32px;
    background-color: var(--color-grey);
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: -1;
}