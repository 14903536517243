.wizard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.wizard-navigation {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.wizard-navigation Button {
    width: 150px;
}