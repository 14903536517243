.team-room-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.team-room-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.main-layout {
    display: flex;
    flex-grow: 1;
    background-color: var(--color-kameral);
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin: 2px;
    background-color: var(--color-kameral);
    border: 1px solid var(--color-kameral);
    border-radius: 6px;
}