.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 100;
}

.modal-content {
    background-color: white;
    border-radius: 8px;
    width: 580px;
    height: min(85vh, 820px);
    margin-top: 3%;
    position: relative;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
}

.modal-members-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid var(--color-light-grey);
    background-color: white;
    border-radius: 12px 12px 0 0;
}

.modal-members-content {
    flex-grow: 1;
    overflow-y: auto;
}

.members-list {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
}

.members-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 4px 0;
    cursor: pointer;
    color: #333;
    gap: 16px;
}

.member-info {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
}

.admin-label {
    color: var(--color-neutral-400);
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;
}

.members-list li:hover {
    background-color: var(--color-neutral-300);
}

.pending-invitations-list {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
}

.pending-invitations-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 20px;
    margin: 4px 0;
    cursor: pointer;
    color: var(--color-grey);
    gap: 16px;
}

.pending-invitations-list li .pending-invitation-info {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
}

.pending-invitations-list li button {
    transition: all 0.3s ease;
}

.pending-invitations-list li .button.primary {
    transform: scale(1.1);
    background-color: var(--color-primary);
}

.modal-invitation-footer {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-top: 1px solid var(--color-light-grey);
}

.dropdown-list::-webkit-scrollbar {
    width: 6px;
}

.dropdown-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.dropdown-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.modal-members-footer {
    display: flex;
    padding: 20px;
    justify-content: flex-end;
    border-top: 1px solid var(--color-light-grey);
}