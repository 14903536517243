.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 100;
}

.modal-delete-content {
    background-color: white;
    border-radius: 8px;
    width: 580px;
    margin-top: 10%;
    position: relative;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
}

.modal-delete-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid var(--color-light-grey);
}

.modal-delete-center {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
}

.admin-warning {
    color: var(--color-error);
    padding-top: 10px;
}

.modal-delete-footer {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-top: 1px solid var(--color-light-grey);
}