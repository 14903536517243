.iconButtonWrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.iconButton {
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
}

.iconButton.active {
    border-radius: 5px;
    background-color: var(--color-neutral-300);
}

.iconButton:hover {
    border-radius: 5px;
    background-color: var(--color-neutral-300);
    transform: scale(1.1);
}

.iconButtonLabel {
    font-size: 12px;
    color: #333;
}

.xs {
    width: 24px;
    height: 24px;
}

.s {
    width: 36px;
    height: 36px;
}

.m {
    width: 48px;
    height: 48px;
}

.l {
    width: 60px;
    height: 60px;
}