.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: none;
  height: 36px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.primary {
  background-color: #000;
  color: #fff;
}

.primary:hover,
.primary:focus {
  background-color: #333;
  color: #fff;
}

.secondary {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

.secondary:hover,
.secondary:focus {
  background-color: #f0f0f0;
  color: #000;
}

.button:focus {
  outline: 2px solid #005fcc;
  outline-offset: 2px;
}

.button:disabled,
.button[aria-disabled="true"] {
  background-color: #e0e0e0;
  color: var(--color-grey);
  cursor: not-allowed;
}

.google-primary {
  background-color: #000;
  color: #fff;
}

.google-primary:hover,
.google-primary:focus {
  background-color: #333;
  color: #fff;
}

.google-secondary {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

.google-secondary:hover,
.google-secondary:focus {
  background-color: #f0f0f0;
  color: #000;
}