.team-overview-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-overview-content {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  max-width: 800px;
  display: flex;
  padding-top: 20px;
}

.team-overview-container {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px var(--color-light-grey);
  margin-bottom: 50px;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.team-overview-list-title {
  font-size: 14px;
  border-bottom: solid 1px rgba(221, 221, 221, 1);
  margin: 0;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bell-icon {
  font-size: 1.5rem;
  margin-left: 10px;
}

.red-dot {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  background-color: var(--color-error);
  border-radius: 50%;
}

.team-overview-list-title span {
  display: flex;
  align-items: center;
}

.team-overview-title {
  font-size: 2rem;
  transition: color 1s ease;
}

.team-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  transition: box-shadow 0.3s ease;
  position: relative;
  cursor: pointer;
}

.team-item:not(:last-child)::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 12px;
  width: calc(100% - 20px);
  height: 1px;
  background-color: var(--color-light-grey);
}

.team-item:hover {
  background-color: var(--color-lightest-grey);
}

.overview-team-logo {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-radius: 6px;
  background-color: black;
  color: white;
}

.team-information {
  flex-grow: 1;
  color: #333;
  margin-left: 20px;
}

.invite-bell {
  position: relative;
  cursor: pointer;
}

.team-name {
  font-size: 18px;
  font-weight: bold;
}

.team-arrow {
  font-size: 24px;
  color: #333;
}

.new-team-prompt {
  background-color: var(--color-kameral);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 24px 20px 16px;
  display: flex;
  font-size: 14px;
  max-width: 600px;
  gap: 32px;
}

.show-more {
  font-size: 14px;
  color: var(--color-link);
  cursor: pointer;
  padding: 12px;
  text-align: center;
}

.show-more:hover {
  text-decoration: underline;
}