.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
}

.modal-invitation-content {
    background: white;
    border-radius: 8px;
    width: 600px;
    margin-top: 10%;
    position: relative;
}

.modal-invitation-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid var(--color-light-grey);
}

.modal-invitation-center {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
}

.invitation-loader,
.success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.invitation-loader .fa-spinner {
    color: var(--color-dark);
    font-size: 2rem;
}

.success-message .success-icon {
    color: var(--color-success);
    font-size: 2rem;
}

.admin-warning {
    color: var(--color-error);
    padding-top: 10px;
}

.modal-invitation-footer {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-top: 1px solid var(--color-light-grey);
}