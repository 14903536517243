@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500&family=Open+Sans:wght@400;600&display=swap');

:root {
  --color-link: #1264a3;
  --color-link-hover: #004080;
  --color-error: #cc3333;
  --color-success: #70a87c;
  --color-grey: #666666;
  --color-light-grey: rgba(221, 221, 221, 1);
  --color-lightest-grey: rgb(249, 249, 249);
  --color-kameral: #F6F4EE;
  --color-text: #000000;
  --font-primary: "Open Sans", Arial, sans-serif;
  --font-secondary: "Archivo", sans-serif;
  --font-size-base: 18px;
  --line-height-base: 1.6667;
  --color-neutral-25: #FDFDFC;
  --color-neutral-50: #FAFAF9;
  --color-neutral-100: #F5F5F4;
  --color-neutral-200: #E7E5E4;
  --color-neutral-300: #D7D3D0;
  --color-neutral-400: #A9A29D;
  --color-neutral-500: #79716B;
  --color-neutral-600: #57534E;
  --color-neutral-700: #44403C;
  --color-neutral-800: #292524;
  --color-neutral-900: #1C1917;
  --color-neutral-950: #171412;
}

body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--color-text);
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: break-word;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h5, h6 {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-style: normal;
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

h3, h4 {
  font-family: var(--font-secondary);
  font-weight: 500;
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-link);
  text-decoration: none;
}

a:hover {
  color: var(--color-link-hover);
}

.muted-text {
  font-size: 14px;
  color: var(--color-grey);
}