.dropdown-container {
    position: absolute;
    background-color: var(--color-kameral);
    border: 1px solid white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    z-index: 100;
    min-width: 350px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dropdown-header {
    padding: 12px;
    border-bottom: 1px solid #ccc;
    background-color: var(--color-kameral);
    flex: 0 0 auto;
}

.header-text {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.dropdown-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex: 1 1 auto;
}

.dropdown-footer {
    border-top: 1px solid #ccc;
    background-color: var(--color-kameral);
    flex: 0 0 auto;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 4px 0;
    cursor: pointer;
    color: #333;
}

.dropdown-item:hover {
    background-color: var(--color-neutral-300);
}

.dropdown-icon-container {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
}

.dropdown-initial,
.dropdown-logo {
    background-color: black;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}

.dropdown-icon {
    color: black;
    font-size: 16px;
}

.create-icon {
    color: black;
}

.dropdown-list::-webkit-scrollbar {
    width: 6px;
}

.dropdown-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.dropdown-list::-webkit-scrollbar-track {
    background-color: transparent;
}