.custom-input-container {
    display: flex;
    flex-direction: column;
    font-family: 'proxima nova', sans-serif;
    margin-bottom: 20px;
    position: relative;
}

.custom-input-label {
    font-size: 16px;
    font-weight: bold;
}

.input-wrapper {
    position: relative;
    /* width: 550px; */
}

.custom-input-field {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    outline: none;
    box-shadow: 0 0 0 1px transparent;
    box-sizing: border-box;
}

.custom-input-field:focus {
    border: 1px solid black;
    box-shadow: 0 0 0 1px black;
}

.input-error {
    border-color: var(--color-error);
}

.char-counter {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #888;
    pointer-events: none;
}

.error-text {
    font-size: 14px;
    color: var(--color-error);
    margin-top: 4px;
}