.get-started-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.get-started-page-content {
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    max-width: 800px;
    display: flex;
    padding-top: 30px;
}

.authenticate-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    align-items: center;
}

.invitation-section {
    display: flex;
    width: 600px;
    flex-direction: column;
    margin-bottom: 30px;
}

.invitation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-kameral);
    border-radius: 1.3rem;
    margin-bottom: 50px;
    padding: 40px;
    margin-top: 10px;
}

.disabled-invitation {
    position: relative;
    background-color: white;
    width: 300px;
    height: 200px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
    opacity: 1;
    pointer-events: none;
    transition: all 0.5s ease;
    cursor: pointer;
}

.disabled-invitation:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background-color: var(--color-kameral);
    border-bottom: 2px solid black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform 1s ease-in-out;
    z-index: 2;
}

.disabled-invitation:after {
    content: "🎁";
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.open-invitation {
    pointer-events: auto;
    opacity: 1;
}

.open-invitation:before {
    transform: scaleY(0);
}

.invitation-open-animation {
    animation: openLetter 1s ease forwards;
}

@keyframes openLetter {
    0% {
        transform: scaleY(1);
    }

    100% {
        transform: scaleY(0);
    }
}

.invitation-team-logo {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 6px;
    background-color: black;
    color: white;
}

.invitation-team-name {
    padding-top: 20px;
}

.invitation-link {
    cursor: pointer;
    padding-bottom: 20px;
}

.invitation-link:hover {
    text-decoration: underline;
}

.accept-invite-button {
    width: 250px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accept-invite-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 80%;
}

.divider::before,
.divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.divider-text {
    margin: 0 20px;
    color: #666;
    font-size: 14px;
    font-weight: 500;
}