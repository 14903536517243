.create-team-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.create-team-content {
    flex-grow: 1;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}